import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import BackgroundImg from 'gatsby-background-image'
import {useQueryParam, StringParam} from 'use-query-params'

import * as CSSGoodbuy from '../assets/css/pages/goodbuy.module.css'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Extensions from '../components/Extensions'

import {useExtensions, useI18n} from '../hooks'

export default function Goodbye({data}) {
  const extensions = useExtensions(data.allDesktopExtensionsJson.extensions)

  const {t, i18n} = useI18n('goodbyePage')
  const [langParam] = useQueryParam('lang', StringParam)

  useEffect(() => {
    if (langParam) {
      i18n.changeLanguage(langParam).catch(console.error)
    }
  }, [i18n, langParam])

  return (
    <>
      <BackgroundImg
        fluid={data.heroBgImage.sharp.fluid}
        backgroundColor={'var(--color-bg-main)'}
        fadeIn={false}
        className="pb-4">
        <Header />
        <div className={`px-3 py-lg-2 mx-auto text-center ${CSSGoodbuy.heroContent}`}>
          <h1>{t('h1', data.content.h1)}</h1>
          <h3 className="text-white">{t('h3', data.content.h3)}</h3>
          <p className="text-white">{t('p', data.content.p)}</p>
        </div>
      </BackgroundImg>

      <Extensions extensions={extensions} />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    content: goodbyepageJson {
      h1
      h3
      p
    }
    allDesktopExtensionsJson {
      extensions: nodes {
        id
        tag
        title
        description_hero
        description_card
        ctaLbl
        cssFlags
        browsers {
          chrome
          firefox
          edge
        }
        image_hero {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image_card {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    heroBgImage: file(relativePath: {eq: "Hero/images/hero_background.jpg"}) {
      sharp: childImageSharp {
        fluid(maxWidth: 1680, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
